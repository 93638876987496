<template>
  <div
    class="flex flex-col gap-0.5 text-sm rounded-lg px-2.5 py-1.5 font-semibold"
    :class="{
      'bg-gray-100': type != 'dark' && item.action == 'entry',
      'bg-gray-200': type != 'dark' && item.action == 'exit',
      'text-white bg-gray-700 bg-opacity-80 text-opacity-90': type == 'dark'
    }"
  >
    <div class="flex gap-1 item-center">
      <div class="flex items-center justify-center">
        <fw-icon-arrow-down v-if="item.action == 'entry'" class="h-4 w-4 flex-shrink-0 text-primary" />
        <fw-icon-arrow-up v-else class="h-4 w-4 flex-shrink-0 text-red-600" />
      </div>
      <div class="flex-1 flex gap-2 items-center" :class="{ 'text-gray-800': type != 'dark' }">
        <span>{{ $t(item.action) }}</span>
      </div>
      <div
        class="flex gap-1 items-center"
        :class="{ 'text-gray-600': type != 'dark', 'text-gray-400': type == 'dark' }"
      >
        <span v-if="item.record_at">{{ item.record_at | formatTimeSeconds }}</span>
        <fw-icon-clock-line class="h-4 w-4 flex-shrink-0 opacity-80" />
      </div>
    </div>
    <div class="font-normal text-xs flex gap-2 justify-between">
      <div :class="{ 'pt-1.5': showTools }">
        <p class="opacity-75">{{ item.terminal }}</p>
        <p v-if="item.auto_justification" class="font-semibold mb-1">{{ $t('justification') }}</p>
        <div v-if="item.auto_justification">
          {{ item.auto_justification }}
        </div>
      </div>
      <div v-if="showTools">
        <fw-menu-more v-if="showTools" size="xs">
          <b-dropdown-item @click.native="$emit('toggle', item)">
            Alterar para <b class="ml-0.5">{{ item.action === 'entry' ? 'Saída' : 'Entrada' }}</b>
          </b-dropdown-item>
        </fw-menu-more>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showTools: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'light'
    }
  },

  data() {
    return {}
  }
}
</script>

<i18n>
{
  "pt": {
    "entry": "Entrada",
    "exit": "Saída",
    "today": "Hoje",
    "yesterday": "Ontem",
    "justification": "Justificação",
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }

  },
  "en": {
    "entry": "Enter",
    "P20": "Exit",
    "today": "Today",
    "yesterday": "Yesterday",
    "justification": "Justification",
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  }
}
</i18n>
