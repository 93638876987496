<template>
  <section class="bg-gray-800 rounded-xl p-2 flex flex-col gap-2">
    <div class="bg-white bg-opacity-5 flex-1 flex flex-col rounded-xl">
      <Clock class="flex-1" />
      <div v-if="!loading">
        <div
          class="p-2 flex flex-col gap-0.5 overflow-auto h-[10rem] fw-scrollbar dark"
          :class="{ 'justify-center items-center': !attendances.length }"
        >
          <RecordAttendance
            v-for="(item, index) in attendances"
            :key="index"
            :item="item"
            type="dark"
            :show-tools="false"
          />
          <div
            v-if="!attendances.length"
            class="text-center text-xs p-3 text-white opacity-70 flex flex-col gap-1 max-w-[12rem]"
          >
            <span>Hoje ainda não tem marcações de assiduidade.</span>
            <span class="opacity-50">Via UC One ou MyUC</span>
          </div>
          <div v-if="attendances.length" class="text-xs text-gray-500 opacity-80 px-3 py-0.5">
            * Estas marcações podem demorar algum tempo a aparecer na vista semanal.
          </div>
        </div>
      </div>
    </div>
    <div v-if="isTeleworker" class="grid grid-cols-2 gap-5">
      <fw-button
        :type="loading || saving ? 'link-muted' : 'link-primary'"
        extended
        :disabled="saving"
        :loading="loading"
        @click.native="setUserAttendance('entry')"
      >
        Entrar
      </fw-button>
      <fw-button
        :type="loading || saving ? 'link-muted' : 'link-danger'"
        extended
        :disabled="saving"
        :loading="loading"
        @click.native="setUserAttendance('exit')"
      >
        Sair
      </fw-button>
    </div>
  </section>
</template>

<script>
import Clock from '@/components/Clock.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import RecordAttendance from '@/components/records/RecordAttendance.vue'

export default {
  components: {
    Clock,
    RecordAttendance
  },

  data() {
    return {
      loading: true,
      saving: false,
      attendances: [],
      stats: {}
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    isTeleworker() {
      return this.$store.state.session.isTeleworker
    }
  },

  created() {
    this.getUserAttendances()
  },

  methods: {
    async getUserAttendances() {
      this.loading = true

      const today = Dates.now()

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserAttendances({
          start_at: today.format('YYYY-MM-DD'),
          end_at: today.format('YYYY-MM-DD'),
          from_sap: false
        })
        console.log('getUserAttendances TODAY :>> ', response)

        this.attendances = response.attendances

        console.log('this.attendances :>> ', this.attendances)
      })

      this.loading = false
    },

    async setUserAttendance(action) {
      console.log('action :>> ', action)
      this.saving = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.setTeleworkUserAttendance(action)
        console.log('setTeleworkUserAttendance :>> ', response)
        this.attendances.unshift(response.attendance)
      })
      this.saving = false
    }
  }
}
</script>
