<template>
  <div>
    <div v-if="!loading" class="digital-clock">
      <div class="digit digit-hour">{{ hour }}</div>
      <div class="dots">:</div>
      <div class="digit digit-min">{{ min }}</div>
      <div class="dots">:</div>
      <div class="digit digit-sec">{{ sec }}</div>
    </div>
    <div v-else class="text-xs text-center py-2 opacity-40 text-white bg-white bg-opacity-10 rounded-lg">
      A sincronizar relógio...
    </div>
  </div>
</template>

<script>
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  data() {
    return {
      hour: '',
      min: '',
      sec: '',
      secDeg: 0,
      minDeg: 0,
      hourDeg: 0,
      loading: true,
      now: 0
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    }
  },

  created() {
    this.getServerDate()
  },

  methods: {
    async getServerDate() {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getDateTime()
        console.log('getDateTime :>> ', response)
        this.now = new Date(response)
        this.updateClock()
      })
      this.loading = false
    },

    clock() {
      this.now.setMilliseconds(1000)
      this.hour = this.zeroPadded(this.now.getHours())
      this.min = this.zeroPadded(this.now.getMinutes())
      this.sec = this.zeroPadded(this.now.getSeconds())
      this.secDeg = (this.sec / 60) * 360 + 90
      this.minDeg = (this.min / 60) * 360 + (this.sec / 60) * 6 + 90
      this.hourDeg = (this.hour / 12) * 360 + (this.min / 60) * 30 + 90
    },

    updateClock() {
      setInterval(() => this.clock(), 1000)
    },

    zeroPadded(num) {
      if (num < 10) {
        return `0${num}`
      }
      return num
    }
  }
}
</script>

<style scoped>
.digital-clock {
  @apply flex justify-center items-center text-4xl font-semibold rounded-lg p-2 text-white h-full;
}
.dots {
  @apply text-xl;
}
.digit {
  @apply justify-center items-center flex w-[3rem] h-10 p-1;
}
.digit-sec {
  @apply opacity-50;
}
</style>
