<template>
  <fw-layout wide :back-to-enable="false" footer mobile-ready>
    <template #header-nav>
      <HeaderSwitchManage />
    </template>

    <template #main-content>
      <PanelUCIdHero
        :user="user"
        class="mt-4 mb-10"
        version="v2"
        :stats="stats"
        :stats-loading="loading.stats"
        :stats-warning="false"
      />

      <fw-panel-info
        v-if="userRoles && userRoles.length && info.newManagementArea === 'show'"
        type="orange"
        boxed
        custom-class="flex gap-5"
        class="md:-mt-7"
      >
        <div>
          <router-link to="/manage">
            <fw-icon-settings class="w-7 h-7 flex-shrink-0" />
          </router-link>
        </div>
        <div class="flex-1 flex flex-col gap-1">
          <div class="flex gap-5">
            <fw-heading class="flex-1">Nova área de gestão</fw-heading>
            <div>
              <a href="#" class="text-xs opacity-80" @click.prevent="dismissInfoNewManagementArea">Esconder</a>
            </div>
          </div>
          <div class="text-sm max-w-4xl">
            Nesta nova versão da MyUC, criámos uma área exclusiva para poder realizar todas as suas atividades de gestão
            MyUC. Para aceder a esta nova área, deverá alterar o âmbito para "Gestão" (opção disponível no canto
            superior esquerdo). Sempre que desejar, no mesmo local, poderá alternar entre âmbito pessoal e o de gestão.
          </div>
        </div>
      </fw-panel-info>

      <section class="flex flex-col-reverse md:flex-row gap-5 my-5">
        <fw-panel :title="$t('quickAccess')" class="flex-1">
          <div
            class="grid gap-4 grid-cols-2"
            :class="{
              'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': isTeleworker,
              'md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-7': !isTeleworker,
              'h-full': isTeleworker && isStaffActiveOrInactive
            }"
          >
            <button-quick-access
              v-if="betamode && isStaffActiveOrInactive"
              :label="$t('personalDetails')"
              :title="$t('personalDetails')"
              @clicked="$router.push('/personal-details')"
            />
            <button-quick-access
              v-if="betamode && isStaffActiveOrInactive"
              :label="$t('receipts')"
              :title="$t('receipts')"
              @clicked="$router.push('/receipts')"
            />
            <button-quick-access
              v-if="isStaffActiveOrInactive"
              label="Assiduidade"
              title="Marcações"
              icon="clock-line"
              @clicked="$router.push('/attendances')"
            />
            <button-quick-access
              v-if="isStaffActiveOrInactive"
              label="Assiduidade"
              title="Ausências"
              icon="info"
              @clicked="$router.push('/absences')"
            />
            <button-quick-access
              v-if="userMap?.key"
              label="Mapas"
              title="Férias"
              :tag="isStarterMap ? `${userMap.year} já disponível` : null"
              :tag-type="isStarterMap ? 'primary' : null"
              icon="calendar"
              @clicked="goToMap(userMap?.key)"
            />
            <button-quick-access
              v-if="hasEnrollments"
              label="Formação"
              title="Certificados"
              icon="award"
              @clicked="$router.push('/certificates')"
            />
            <button-quick-access
              v-if="betamode && isStaffActiveOrInactive"
              :label="$t('irs')"
              :title="$t('irs')"
              icon="survey"
              @clicked="$router.push('/irs')"
            />
            <button-quick-access
              label="Suporte"
              title="Precisa de ajuda?"
              icon="question"
              @clicked="$router.push('/support')"
            />
          </div>
        </fw-panel>
        <fw-panel v-if="isTeleworker" title="Teletrabalho">
          <PanelTeleworkAttendance class="w-full md:w-[24rem]" />
        </fw-panel>
      </section>

      <fw-panel title="Inbox">
        <PanelInboxResume />
      </fw-panel>

      <WeeklyEventsPanel class="my-5" :loading="loading.weekData" :weeks="weeks" @get-week-events="getUserWeekEvents">
        <template #events="{events}">
          <div
            v-for="(evt, ee) in events"
            :key="'event_' + ee"
            class="flex flex-col"
            :class="{
              'flex-1':
                ['holiday', 'birthday'].includes(evt.type) || (evt.type == 'vacation' && evt.day_type == 'allday')
            }"
          >
            <!-- Holidays -->
            <div
              v-if="evt.type == 'birthday'"
              class="eventbox bg-primary text-white flex flex-col gap-2 flex-1 items-center justify-center p-3"
            >
              <fw-icon-smile class="h-6 w-6"></fw-icon-smile>
              <div>{{ $t('happyBirthday') }}!</div>
              <div class="text-xs opacity-75">{{ $t('dayIsYours') }}.</div>
            </div>

            <div
              v-else-if="evt.type == 'holiday'"
              class="eventbox bg-gray-400 text-white flex flex-col flex-1 items-center justify-center font-bold p-3"
            >
              <div>{{ $t('holiday') }}</div>
            </div>

            <div
              v-else-if="evt.type == 'vacation'"
              class="eventbox bg-primary text-white flex items-center justify-center gap-2"
              :class="{
                'flex-col flex-1 p-3': evt.day_type == 'allday',
                'p-3': evt.day_type != 'allday'
              }"
            >
              <div>
                <fw-icon-sun class="h-6 w-6"></fw-icon-sun>
              </div>
              <div class="flex flex-col items-center justify-center">
                <div>Férias</div>
                <div class="text-xs opacity-75">
                  {{ evt.day_type == 'allday' ? $t('allDay') : $t(`dayType.${evt.day_type}`) }}
                </div>
              </div>
            </div>

            <!-- Attendances -->
            <div
              v-else-if="evt.type == 'entry' || evt.type == 'exit'"
              class="eventbox flex item-center gap-1"
              :class="{
                'bg-gray-100': evt.type == 'entry',
                'bg-gray-200': evt.type == 'exit'
              }"
            >
              <div class="flex items-center justify-center">
                <fw-icon-arrow-down v-if="evt.type == 'entry'" class="h-4 w-4 flex-shrink-0 text-primary" />
                <fw-icon-arrow-up v-else class="h-4 w-4 flex-shrink-0 text-red-600" />
              </div>
              <div class="flex-1 flex gap-2 items-center text-gray-800">
                <span>{{ $t(evt.type_code) }}</span>
              </div>
              <div class="flex gap-1 items-center text-gray-600">
                <span v-if="evt.time && splitTime(evt.time)">
                  <span>{{ splitTime(evt.time)['hours'] }}</span>
                  <span>:{{ splitTime(evt.time)['minutes'] }}</span>
                  <span class="font-medium text-gray-500">:{{ splitTime(evt.time)['seconds'] }}</span>
                </span>
                <fw-icon-clock-line class="h-4 w-4 flex-shrink-0 opacity-80" />
              </div>
            </div>
          </div>
        </template>
      </WeeklyEventsPanel>

      <fw-panel-info label="Data (raw)" debug class="my-5">
        <json-viewer :value="{ stats, userMap, receipts, weeks }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import PanelUCIdHero from '@/fw-modules/fw-core-vue/id/components/panels/PanelUCIdHero'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import WeeklyEventsPanel from '@/fw-modules/fw-core-vue/ui/components/panels/WeeklyEventsPanel.vue'
import ButtonQuickAccess from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonQuickAccess'
import HeaderSwitchManage from '@/components/header/HeaderSwitchManage'
import { MAP_USER_STATUS_COLORS, MAP_USER_STATUS } from '@/utils/index.js'
import PanelInboxResume from '@/components/panels/PanelInboxResume'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import PanelTeleworkAttendance from '@/components/panels/PanelTeleworkAttendance'

export default {
  components: {
    ButtonQuickAccess,
    PanelUCIdHero,
    WeeklyEventsPanel,
    HeaderSwitchManage,
    PanelInboxResume,
    PanelTeleworkAttendance
  },

  data() {
    return {
      colors: MAP_USER_STATUS_COLORS,
      statusLabels: MAP_USER_STATUS,
      stats: [],
      userMap: null,
      today: Dates.now(),
      year: null,
      month: null,
      receipts: [],
      irs: [],
      weeks: {},
      downloading: false,
      hasEnrollments: false,
      loading: {
        initial: true,
        data: true,
        stats: true,
        weekData: true,
        enrollments: true
      },
      info: {
        newManagementArea: 'dismiss'
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    userRoles() {
      return this.$store.getters.getUserPermissions
    },

    isNewReceipt() {
      return (
        this.receipts &&
        this.receipts[0].month == this.month &&
        this.receipts[0].year == this.year &&
        !this.receipts[0].seen
      )
    },

    language() {
      return this.$store.state.language
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    betamode() {
      return Boolean(localStorage.getItem('beta'))
    },

    isStarterMap() {
      return (
        this.userMap.year == this.year &&
        this.userMap.version_motive === 'starter_map' &&
        this.userMap.status == 'draft'
      )
    },

    hasMaps() {
      return this.userMaps && this.userMaps.length > 0
    },

    isTeleworker() {
      return this.$store.state.session.isTeleworker
    },

    // John Santos's awesome rule
    isStaffActiveOrInactive() {
      return this.user && this.user?.number && this.user?.number < 90000
    }
  },

  created() {
    this.month = this.today.month()
    this.year = this.today.year()
    ServiceSettings.checkAppVersion()
  },

  beforeMount() {
    this.loadStats()
    this.getUserEnrollments()
    this.checkInfoNewManagementArea()
  },

  methods: {
    goToMap(key) {
      this.$router.push({ name: 'map', params: { key: key } })
    },

    async checkInfoNewManagementArea() {
      let action = 'show'
      const infoCode = 'myuc-info-new-management-dismiss'
      if (localStorage.getItem(infoCode)) {
        action = 'dismiss'
      } else {
        const info = await ServiceSettings.getSetting(infoCode)
        if (info) {
          action = 'dismiss'
          localStorage.setItem(infoCode, action)
        }
      }
      this.info.newManagementArea = action
    },

    async dismissInfoNewManagementArea() {
      const action = 'dismiss'
      const infoCode = 'myuc-info-new-management-dismiss'
      localStorage.setItem(infoCode, action)
      await ServiceSettings.setSetting(infoCode, action)
      this.info.newManagementArea = action
    },

    async getUserEnrollments() {
      await utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getUserEnrollments({ check_exists: true })
          console.log('User enrollments >> ', this.response)
          this.hasEnrollments = response.exists
        },

        // Finally
        () => {
          this.loading.enrollments = false
        }
      )
    },

    async loadStats() {
      this.loading.stats = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserStats()
        console.log('getWorkerBalance :>> ', response)

        this.stats = []

        // Get current balance
        if (response.balance) {
          this.stats.push({
            label: this.$t('currentBalance'),
            value: response.balance,
            description: response.balance_date ? this.$options.filters.formatDate(response.balance_date) : '',
            valueSuffix: 'h',
            customClass: response.balance != null && response.balance < 0 ? 'text-red-500' : ''
          })
        }

        // Get previous month balance
        if (response.balance_last_month) {
          this.stats.push({
            label: this.$t('previousMonthBalance'),
            value: response.balance_last_month,
            valueSuffix: 'h',
            customClass: response.balance_last_month != null && response.balance_last_month < 0 ? 'text-red-500' : ''
          })
        }

        // Get unjustified absences
        if (response.absences > 0) {
          this.stats.push({
            label: this.$t('unjustifiedAbsences'),
            value: response.absences,
            valueSuffix: this.$tc('absences', response.absences),
            customClass: response.absences != null && response.absences > 0 ? 'text-red-500' : ''
          })
        }

        if (response.map_user) {
          this.userMap = response.map_user
        }
      })

      this.loading.stats = false
    },

    async getUserWeekEvents(weekNumber, year) {
      this.loading.weekData = true

      console.log('getUserWeekEvents for :>> ', weekNumber, year)

      await utils.tryAndCatch(this, async () => {
        const data = await this.api.getUserWeekEvents(weekNumber, year)
        this.weeks = Object.assign({}, this.weeks, data.weeks)
      })
      this.loading.weekData = false
    },

    splitTime(timeString) {
      if (!timeString) return {}
      const time = timeString.split(':')
      return {
        hours: time[0],
        minutes: time[1],
        seconds: time[2]
      }
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "attendances": "Assiduidade",
    "timetableType": "Tipo de horário",
    "currentBalance": "Saldo atual",
    "previousMonthBalance": "Saldo mês anterior",
    "unjustifiedAbsences": "Faltas por justificar",
    "receipts": "Recibos de vencimento",
    "absence": "falta",
    "absences": "Ausências",
    "quickAccess": "Acesso rápido",
    "quickAction": "Ação rápida",
    "availableNow": "Já disponível",
    "receipt": "Recibo",
    "newReceipt": "Novo recibo",
    "holiday": "Feriado",
    "P10": "Entrada",
    "P20": "Saída",
    "happyBirthday": "Parabéns",
    "dayIsYours": "Este dia é seu",
    "maps": "Mapas",
    "allDay": "Todo o dia",
    "halfDay": "Meio dia",
    "attendances": "Marcações",
    "irs": "Declarações IRS",
    "personalDetails": "Dados pessoais",
    "dayType": {
      "allday": "Todo o dia",
      "morning": "Manhã",
      "afternoon": "Tarde"
    },
    "months": {
      "1": "Janeiro",
      "2": "fevereiro",
      "3": "Março",
      "4": "Abril",
      "5": "Maio",
      "6": "Junho",
      "7": "Julho",
      "8": "Agosto",
      "9": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro"
    }
  },
  "en": {
    "irs": "IRS Declarations",
    "personalDetails": "Personal details",
    "attendances": "Attendances",
    "attendances": "Attendances",
    "timetableType": "Type of timetable",
    "currentBalance": "Current balance",
    "previousMonthBalance": "Previous month balance",
    "unjustifiedAbsences": "Unjustified absences",
    "absence": "absence",
    "absences": "Absences",
    "absences": "Absences",
    "quickAccess": "Quick access",
    "quickAction": "Quick action",
    "availableNow": "Available now",
    "receipt": "Receipt",
    "newReceipt": "New receipt",
    "holiday": "Holiday",
    "P10": "Enter",
    "P20": "Exit",
    "happyBirthday": "Happy Birthday",
    "dayIsYours": "This day is yours",
    "receipts": "Pay slips",
    "maps": "Maps",
    "allDay": "All day",
    "halfDay": "Half day",
    "dayType": {
      "allday": "All day",
      "morning": "Morning",
      "afternoon": "Afternoon"
    },
    "months": {
      "1": "January",
      "2": "February",
      "3": "March",
      "4": "April",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "August",
      "9": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    }
  }
}
</i18n>
